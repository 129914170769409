import { version } from '../../package.json'
import i18n from '../plugins/i18n'
import axios from '@/plugins/axios'

export default {
    data: () => ({
        type_return: 'return',
        type_to_return: 'to_return',
        type_network: 'network',
        type_edit: 'edit',
        type_pnes: 'pnes',
        type_associate_to_admin: 'associate',
        type_pnes_module: 'pnes_module',
        type_details: 'details',
        type_device_to_link_to_network: 'device_to_link_to_network',
        type_network_device: 'network_device',
        error_display: {
            error_global: i18n.t('error_display.error_global'),
            device_not_found: i18n.t('error_display.device_not_found'),
            device_cant_be_added_to_new_order: i18n.t('error_display.device_cant_be_added_to_new_order'),
            asset_not_found: i18n.t('error_display.asset_not_found'),
            device_has_no_asset: i18n.t('error_display.device_has_no_asset'),
            wrong_credentials: i18n.t('error_display.wrong_credentials'),
            login_error: i18n.t('error_display.login_error'),
            device_not_returnable: i18n.t('error_display.device_not_returnable'),
            contrat_device_not_valid_for_device: i18n.t('error_display.contrat_device_not_valid_for_device'),
            contrat_device_not_belong_to_professional: i18n.t('error_display.contrat_device_not_belong_to_professional'),
            device_cannot_replace_returned_device: i18n.t('error_display.device_cannot_replace_returned_device'),
            device_and_network_device_professional_are_different: i18n.t('error_display.device_and_network_device_professional_are_different'),
            network_device_already_installed: i18n.t('error_display.network_device_already_installed'),
            not_pnes: i18n.t('error_display.not_pnes'),
            pnes_without_phone_number: i18n.t('error_display.pnes_without_phone_number'),
        },
        is_online: navigator.onLine,
        deferredPrompt: null,
        app_version: version,
    }),
    methods: {
        displayToHumanTrigger(item) {
            switch (item) {
                case -2:
                    return 'Trigger_courbe'
                case -1:
                    return 'Trigger_buildup'
                case 0:
                    return 'Power_ON'
                case 1:
                    return 'Journalier'
                case 2:
                    return 'Accelerometre'
                case 3:
                    return 'Manuel'
                case 4:
                    return 'Sentinelle'
                case 5:
                    return 'Installation'
                case 6:
                    return 'Downlink'
                case 7:
                    return 'Ack Downlink'
                case 8:
                    return 'Mise en service'
                case 9:
                    return 'Factory'
                case 10:
                    return 'Penta_trame'
                case 11:
                    return 'Arret'
                case 12:
                    return 'Inconnu'
                case 13:
                    return 'Calibration'
                case 14:
                    return 'Watchdog'
            }
        },

        displayToHumanAccel($var) {
            switch (parseInt($var)) {
                case 1:
                    return i18n.t('global.vertical')
                case 2:
                    return i18n.t('global.reverse_vertical')
                case 3:
                    return i18n.t('global.horizontal')
                case -1:
                    return i18n.t('global.faulty')
                default:
                    return '/'
            }
        },

        convertErrorToDisplay(erreur) {
            if (erreur < 0) {
                return erreur
            }

            const erreur_binary = parseInt(erreur, 10).toString(2).padStart(4, '0').toString()

            if (erreur_binary.slice(2, 3) === '1') {
                return 1 //trop près
            } else if (erreur_binary.slice(1, 2) === '1') {
                return 2 //trop loin
            } else if (erreur_binary.slice(0, 1) === '1') {
                return 3 //trop echo
            } else if (erreur_binary.slice(3, 4) === '1') {
                return 4 //erreur accel
            }
            return 0
        },

        displayToHumanError(erreur, height_liquid) {
            switch (this.convertErrorToDisplay(erreur)) {
                case -1:
                    return i18n.t('global.unreliable_measurement')
                case 0:
                    if (height_liquid < -10) {
                        return i18n.t('global.incorrectly_configured_container')
                    }
                    return '✓'
                case 1:
                    return i18n.t('global.full')
                case 2:
                    return i18n.t('global.too_far_away')
                default:
                    return '/'
            }
        },
        displayHumanSiteAddress(site, message = 'Aucun site associé') {
            if (site) {
                return `${site.address_2 !== '' ? `${site.address_2}, ` : ''} ${
                    site.street_number && site.street_number !== '0' ? site.street_number : ''
                } ${site.address ? site.address : ''}, ${site.postal_code ? site.postal_code : ''} ${site.city ? site.city : ''}`
            }

            return message
        },

        manageError(error) {
            if (!error.response) {
                //If is it an error js
                this.postElasticSearchLogFront(error)
            }

            if (!this.is_online) {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'error',
                    text: i18n.t('global.no_internet_connection'),
                })
            } else {
                let type = 'error'
                let text = i18n.t('error_display.error_global')

                if (error.response !== undefined) {
                    if (
                        i18n.t(`error_display.${error.response.data.translation_key}`) !==
                        `error_display.${error.response.data.translation_key}`
                    ) {
                        text = i18n.t(`error_display.${error.response.data.translation_key}`)
                    }

                    if (error.response.status === 401) {
                        type = 'warning'
                    }
                }

                this.$store.commit('snackbar/showSnackbar', { color: type, text: text })
            }
        },

        async postElasticSearchLogFront(error) {
            const token = window.localStorage.getItem('auth_token_default')

            let ip_client = 'unknown'
            await axios
                .get('https://api-bdc.net/data/client-ip', {
                    transformRequest: (data, headers) => {
                        delete headers['Authorization']
                    },
                })
                .then((success) => {
                    ip_client = success.data.ip_string
                })

            await axios.post(
                `${process.env.VUE_APP_API_URL}api/v1/elastic_search/log`,
                {
                    error: error.message ? error.message : 'empty',
                    message: error.stack ? error.stack : 'empty',
                    ip_client: ip_client,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
        },

        copy(value) {
            navigator.clipboard.writeText(value).then(() =>
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'info',
                    text: i18n.t('global.copy_to_clipboard'),
                }),
            )
        },

        checkImageToUpload(image) {
            let valid = true
            if (image.type === '' || !'image/png image/jpeg image/jpg'.includes(image.type)) {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'warning',
                    text: i18n.t('global.unauthorized_file_type'),
                })
                valid = false
            }
            if (image.size > 10000000) {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'warning',
                    text: i18n.t('global.file_too_large'),
                })
                valid = false
            }

            return valid
        },
    },
    watch: {
        $route(to, from) {
            if (from.name === 'check_network' || from.name === 'listen_mode') {
                this.$store.commit('clearIntervalAutoRefresh')
            }
        },
        is_online() {
            if (!this.is_online) {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'error',
                    text: i18n.t('global.no_internet_connection'),
                })
            }
        },
    },
    mounted() {
        if (localStorage.getItem('last_devices_entered')) {
            try {
                this.$store.state.last_devices_entered = JSON.parse(localStorage.getItem('last_devices_entered'))
            } catch (e) {
                localStorage.removeItem('last_devices_entered')
            }
        }

        window.addEventListener('online', () => {
            this.is_online = navigator.onLine
        })

        window.addEventListener('offline', () => {
            this.is_online = navigator.onLine
        })

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e
        })
    },
}
